import React, { useState, useEffect } from "react";
//import logo from "./logo.svg";
import logo from "./logo-enterprise.png";
import "./App.css";
import { useParams } from "react-router-dom";
import axios from "axios";

function App() {
  let { id, stage } = useParams();
  console.log("ID", id);
  console.log("stage", stage);
  const [responseSubmitted, setResponseSubmitted] = useState(false);
  const [firstAttempt, setFirstAttempt] = useState(true);

  let brandClass = "enterprise";
  if (window.location.hostname.includes("alamo")) {
    brandClass = "alamo";
  } else if (window.location.hostname.includes("national")) {
    brandClass = "national";
  }

  const positionSuccess = async (response: any) => {
    let postObject = {
      numberCode: id,
      stage: stage,
      success: true,
      latitude: response.coords.latitude,
      longitude: response.coords.longitude,
      accuracy: response.coords.accuracy,
    };

    console.log(postObject);
    try {
      let postResponse = await axios.post("/postLocation", postObject);
      setResponseSubmitted(true);
    } catch {}
  };

  const positionError = async (response: any) => {
    try {
      if (!firstAttempt) {
        let postResponse = await axios.post("/postLocation", {
          numberCode: id,
          stage: stage,
          success: false,
          message: response.message,
        });
        setResponseSubmitted(true);
      } else {
        setFirstAttempt(false);
      }
    } catch {}
  };

  const getPosition = () => {
    const geo = navigator.geolocation;
    if (!geo) {
      console.log("Geolocation is not supported");
      positionError({ message: "Geolocation is not supported" });
      return;
    }
    geo.getCurrentPosition(positionSuccess, positionError);
  };
  //console.log("AAAA");

  useEffect(() => getPosition(), []);
  //getPosition();

  return (
    <div className="App">
      <header className={"App-header " + brandClass}>
        <img src={"/" + brandClass + ".png"} className="App-logo" alt="logo" />
        <p className={"App-body " + brandClass}>
          {!responseSubmitted && (
            <i>
              {" "}
              Please make sure location services are enabled to allow this page
              to determine your location. This page will pass location
              information back to the call you are currently on. If you are not
              prompted to share your location,{" "}
              <a
                className="App-link"
                onClick={() => getPosition()}
                target="_blank"
                rel="noopener noreferrer"
              >
                click here
              </a>
            </i>
          )}
          {responseSubmitted && (
            <i>Your response has been submitted. Please return to your call.</i>
          )}
        </p>
      </header>
    </div>
  );
}

export default App;
